<template>

<div class="block-wrap">

<!-- 
    <a :href="`/admin/clients/edit/${getClientSlug}`"  class="token-button" v-if="!hasTokens">Authenticate</a> -->

    <button v-if="blockDisable" class="btn-primary btn-primary--outline block-btn" @click.prevent="onAuth()">Authenticate</button>
	<div class="block seo yellow" v-bind:class="blockDisable">
		<router-link :to="{path: `/client/${getClientSlug}/seo`}">
			<h3>SEO</h3>
			<div class="row">
				<p>Google Ranking change</p>
				<div class="stats">
					<div class="conversion-rate" v-bind:class="seoConversionClass">
						<div class="grid-container">
							<div class="col">
								<p>{{ seoConversion }}</p>
							</div>
							<div class="col">
								<span class="arrow">
									<i class="fa-solid fa-arrow-up-long"></i>
								</span>
							</div>
						</div>
					</div>
					<!-- end conversion-rate -->
				</div>
				<!-- end stats -->
				<p class="dark">than last month</p>
			</div>
			<!-- end row -->
			<div class="row graph-row">
				<div class="graph">
					<apexchart ref="seoChart" type="area" height="100" :options="chartOptions" :series="series" v-if="series"></apexchart>
				</div>
				<!-- end graph -->
			</div>
			<!-- end row -->
			<span class="arrow-link">SEO information</span>
		</router-link>
	</div>
</div>
</template>

<script>
import {mapGetters} from "vuex"

let dailyHits = []
let dailyLabels = []
// const metricMom = 'sessionsMom';
const metricDays = 'googleRankingChanges';
const arbitraryReversalAmount = 1000000; // See explanation in /seo/HeaderGraphBlock.vue


export default {
	data() {
		return {
            seoConversion: '--',
            conversionClass: 'up',
            blockDisable : 'disabled',
            hasTokens: false,
			// Chart settings, note: blank series initially so data can be loaded in.
            chartOptions: {
                chart: {
                    id: 'organicSocialChart',
                    type: 'area',
                    animations: {
                        enabled: false,
                    },
                    sparkline: {
                        enabled: true,
                    },
                    selection: {
                        enabled: false,
                    },
                },
                colors: ['#F6C844'],
                fill: {
                    gradient: {
                        enabled: true,
                        opacityFrom: 0.35,
                        opacityTo: 0
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
					crosshairs: {
                        show: false,
                    },
                },
                stroke: {
                    width: 2,
                    lineCap: 'round',
                    curve: 'smooth'
                },
                tooltip: {
                    enabled: false,
                },
                noData: {
                  text: 'Loading...'
                }
            },
		}
	},
    computed: {
        googleData: {
            get() {
				return this.$store.getters["client/getClientGoogleData"] || ""
            },
		},
		...mapGetters("client", ["getClientSlug", "getSocial", "getComponents"]),
    },
    watch:{
        'getSocial.socialAccountInformation.google': {
        deep: true,
            handler(newValue) {
                this.init(newValue.seoValid);
            }
        }
    },
    async mounted(){

        try{
            await this.init(this.getSocial.socialAccountInformation.google.seoValid);

        }catch(error){

            console.log('mounted error', error);

        }
        
    },
    methods:{
        async init(isValid){

            dailyHits = [];
            
            try{
                if(isValid){

                    if(this.googleData.metrics){

                        if(this.googleData.metrics[metricDays]){

                            let runningTotalCurrent = 0;
                            let runningTotalPrevious = 0;
                            // Show the graph, loop through the last 30 days of hits and on the last loop update the graph series
                            for (var j = 28; j > 0; j--) {
                                var savedDate = new Date();
                                savedDate.setDate(savedDate.getDate() - j);
                                let thedate = new Date(savedDate);
                                savedDate = savedDate.toISOString().slice(0, 10);
                                let currentDaysRanking = (this.googleData.metrics[metricDays][savedDate] * -1) + arbitraryReversalAmount
                                runningTotalCurrent = runningTotalCurrent + this.googleData.metrics[metricDays][savedDate];

                                // Check that the current hits amount is a number, incase it's not in the list for any reason.
                                if (Number.isInteger(currentDaysRanking)) {
                                    dailyHits.push(currentDaysRanking)
                                    let mon = thedate.toLocaleString('en-UK', {month: 'short'});
                                    let dd = thedate.getDate()
                                    dailyLabels.push(mon + ' ' + dd)
                                }
                                if (j === 1) {
                                    this.series = [{
                                    name: 'Hits',
                                    data: dailyHits,
                                    }]
                                }
                            }
                            // Calculate the previous month
                            for (var k = 56; k > 28; k--) {
                                var savedDatePrev = new Date();
                                savedDatePrev.setDate(savedDatePrev.getDate() - k);
                                savedDatePrev = savedDatePrev.toISOString().slice(0, 10);

                                if(typeof this.googleData.metrics[metricDays][savedDatePrev] === 'number'){

                                    runningTotalPrevious = runningTotalPrevious + this.googleData.metrics[metricDays][savedDatePrev];

                                }
                                
                            }

                            this.seoConversion = Math.round(runningTotalPrevious / 28) - Math.round(runningTotalCurrent / 28)
                            this.conversionClass = this.conversionRate(this.seoConversion);

                        }

                        this.hasTokens = true;
                        this.blockDisable = '';

                    }
                }else{
                    this.hasTokens = false;
                    this.blockDisable = 'disabled'; 
                }
               
            }catch(error){
                console.log('anayltics block init error', error);
            }

        },
        onAuth(){
            this.$store.commit('client/setSocial', {prop: 'modalIsOpen', val: true});
            alert('[Agency Campaign] must be valid\nLocation: Google account information');
        }
    },
}
</script>
