<template>
    <div class="block-wrap">

        <!-- <a :href="`/admin/clients/edit/${getClientSlug}`" class="token-button" v-if="!hasTokens">Authenticate</a> -->

        <button v-if="blockDisable" class="btn-primary btn-primary--outline block-btn" @click.prevent="onAuth()">Authenticate</button>
	<div class="block social orange" v-bind:class="blockDisable">
		<router-link :to="{path: `/client/${getClientSlug}/organic-social`}">
			<h3>Organic Social</h3>
			<div class="row">
				<p>Facebook organic conversions</p>
				<div class="stats">
					<p class="number">{{ organicSessionsVal }}</p>
					<div class="conversion-rate" v-bind:class="organicConversionClass">
						<div class="grid-container">
							<div class="col">
								<p>{{ organicConversionAmount }}</p>
							</div>
							<div class="col">
								<span class="arrow">
									<i class="fa-solid fa-arrow-up-long"></i>
								</span>
							</div>
						</div>
					</div>
					<!-- end conversion-rate -->
				</div>
				<!-- end stats -->
			</div>
			<!-- end row -->
			<div class="row graph-row">
				<div class="graph">
					<apexchart ref="organicSocialChart" type="area" height="100" :options="chartOptions" :series="series" v-if="series"></apexchart>
				</div>
				<!-- end graph -->
			</div>
			<!-- end row -->
			<span class="arrow-link">Organic Social information</span>
		</router-link>
	</div>
</div>
</template>

<script>
import {mapGetters} from "vuex"

let dailyHits = []
let dailyLabels = []
const metricMom = 'sessionsMom';
const metricDays = 'sessionsByDay';

export default {

    computed: {
        googleData: {
            get() {
				return this.$store.getters["client/getClientGoogleData"] || ""
            },
		},
		...mapGetters("client", ["getClientSlug", "getSocial", "getComponents"]),
	},
    mounted(){
    
        if(Object.keys(this.getSocial.socialAccountInformation.google).length > 0){
            this.tokens.googleName = this.getSocial.socialAccountInformation.google.name;
            this.tokens.googleView = this.getSocial.socialAccountInformation.google.GA4PropertyName;
        }
        if(Object.keys(this.getSocial.socialAccountInformation.meta).length > 0){
            this.tokens.metaPage = this.getSocial.socialAccountInformation.meta.fbPageTokenStatus.is_valid;
            this.tokens.metaUser = this.getSocial.socialAccountInformation.meta.fbUserTokenStatus.is_valid;
        }

        this.init();
    },
    watch:{
        'getSocial.socialAccountInformation.meta': {
        deep: true,
            handler(newValue) {

                this.tokens.metaPage = newValue.fbPageTokenStatus.is_valid;
                this.tokens.metaUser = newValue.fbUserTokenStatus.is_valid;

                this.init();
            }
        },
        'getSocial.socialAccountInformation.google': {
        deep: true,
            handler(newValue) {

                this.tokens.googleName = newValue.name;
                this.tokens.googleView = newValue.GA4PropertyName;

                this.init();
            }
        }
    },

    methods:{
        onAuth(){
            const val = this.tokens.googleName ? '' : '[Google Account] must be valid\n';
			const val2 = this.tokens.googleView ? '' : '[Google GA4 property] must be valid\n'
            const val3 = this.tokens.metaPage ? '' : '[Meta Facebook page] must be valid\n'
            const val4 = this.tokens.metaUser ? '' : '[Meta Account] must be valid\n'
			const str = val || val2 ? `Location: Google account information\n\n` : '';
            const str1 = val3 || val4 ? `Location: Meta account information\n\n` : '';
			alert(`${val}${val2}${str}${val3}${val4}${str1}`);
            this.$store.commit('client/setSocial', {prop: 'modalIsOpen', val: true})
        },
        init(){
            
            // Show the month-on-month session data
            dailyHits = [];
            
            if(this.tokens.googleName
            && this.tokens.googleView 
            && this.tokens.metaPage 
            && this.tokens.metaUser){

                if(this.googleData.metrics){

                    if(this.googleData.metrics[metricMom]){

                        this.organicSessionsVal = this.googleData.metrics[metricMom].currentOrganic
                        this.organicConversionAmount = (this.organicSessionsVal - this.googleData.metrics[metricMom].previousOrganic);
                        this.organicConversionClass = this.conversionRate(this.organicConversionAmount)
                        // Show the graph, loop through the last 30 days of hits and on the last loop update the graph series
                        for (var j = 30; j > 0; j--) {
                            var savedDate = new Date();
                            savedDate.setDate(savedDate.getDate() - j);
                            let thedate = new Date(savedDate);
                            savedDate = savedDate.toISOString().slice(0, 10);
                            let currentDaysHits = this.googleData.metrics[metricDays].organic[savedDate]
                            // console.log(this.googleData.metrics[metricDays].organic[savedDate])

                            // Check that the current hits amount is a number, incase it's not in the list for any reason.
                            if (Number.isInteger(currentDaysHits)) {
                                dailyHits.push(currentDaysHits)
                                let mon = thedate.toLocaleString('en-UK', {month: 'short'});
                                let dd = thedate.getDate()
                                dailyLabels.push(mon + ' ' + dd)
                            }
                            if (j === 1) {
                                this.series = [{
                                name: 'Hits',
                                data: dailyHits,
                                }]
                            }
                        }

                        if(dailyHits.length === 0){

                            this.chartOptions.noData.text = 'No Data';

                        }

                    }

                }

                this.hasTokens = true;
                this.blockDisable = '';

            }else{
                this.hasTokens = false;
                this.blockDisable = 'disabled';
            }

        }
    },

	data() {
		return {
            tokens: {},
            organicSessionsVal: '--',
            organicConversionAmount: '--',
            organicConversionClass: 'up',
            blockDisable : 'disabled',
            hasTokens: false,
			// Chart settings, note: blank series initially so data can be loaded in.
            chartOptions: {
                chart: {
                    id: 'organicSocialChart',
                    type: 'area',
                    animations: {
                        enabled: false,
                    },
                    sparkline: {
                        enabled: true,
                    },
                    selection: {
                        enabled: false,
                    },
                },
                colors: ['#FF8344'],
                fill: {
                    gradient: {
                        enabled: true,
                        opacityFrom: 0.35,
                        opacityTo: 0
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
					crosshairs: {
                        show: false,
                    },
                },
                stroke: {
                    width: 2,
                    lineCap: 'round',
                    curve: 'smooth'
                },
                tooltip: {
                    enabled: false,
                },
                noData: {
                  text: 'Loading...'
                }
            },
		}
	},
}
</script>