<template>
	<section class="client-overview three-columns">
		<div class="overview-blocks grid-container" v-if="getClientSlug">
			<ContentBlock></ContentBlock>
			<PaidSocialBlock></PaidSocialBlock>
			<OrganicSocialBlock></OrganicSocialBlock>
			<AnalyticsBlock></AnalyticsBlock>
			<SeoBlock></SeoBlock>
			<PpcBlock></PpcBlock>
			<CampaignBlock></CampaignBlock>
		</div>
		<div v-else>
			<h2>Loading...</h2>
		</div>
		<!-- end overview-blocks -->
	</section>
</template>

<script>
import ContentBlock from "../../components/client/ContentBlock.vue"
import PaidSocialBlock from "../../components/client/PaidSocialBlock.vue"
import OrganicSocialBlock from "../../components/client/OrganicSocialBlock.vue"
import AnalyticsBlock from "../../components/client/AnalyticsBlock.vue"
import SeoBlock from "../../components/client/SeoBlock.vue"
import PpcBlock from "../../components/client/PpcBlock.vue"
import CampaignBlock from "../../components/client/CampaignBlock.vue"
import {mapGetters} from "vuex"

export default {
	data() {
		return {
			loginType: "client",
		}
	},
	async mounted(){
		document.querySelector('.main-content').classList.remove('content-list-open');
		const urlParams = new URLSearchParams(window.location.search);
		const socialModal = urlParams.get('googleModal');
		const showLoader = urlParams.get('loader'); //this would exsist if user used 0Auth flow for linkedin or meta and has came back. we have to do this as we must wait for the list of companies to select from
		if(showLoader){
			this.$store.commit('client/setLoaders', {prop: 'hidePageLoader', val: false})
		}else{
			this.$store.commit('client/setLoaders', {prop: 'hidePageLoader', val: true})
		}
		if (socialModal === 'open') {
			this.$store.commit('client/setSocial', {prop: 'googleModalIsOpen', val: true});
		}
	},
	components: {
		ContentBlock,
		PaidSocialBlock,
		OrganicSocialBlock,
		AnalyticsBlock,
		SeoBlock,
		PpcBlock,
		CampaignBlock,
	},
	computed: {
		...mapGetters("client", ["getClientSlug"]),
	},
}
</script>
<style lang="scss">
//@import "./src/assets/scss/dashboard.scss";

.block-wrap{
    position: relative;
}
.token-button{
	z-index: 15;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 150px;
    padding: 12px;
    line-height: normal;
    border-radius: 6px;
    background-color: #8387aa;
    border: 1px solid #8387aa;
	color: #ffffff;
    word-break: break-word;
	outline: none;
    text-decoration: none;
    transition: all ease 0.3s;
	appearance: none;
	height: 45px;
	overflow: auto;
	margin: auto;
	position: absolute;
	top: 0; left: 0; bottom: 0; right: 0;
}
.token-button:hover{
	border-color: $btn-primary;
}
.btn-link-auth-test{
	position: fixed;
    bottom: 20px;
    right: 20px;
    font-size: 14px;
    background: #5858b9;
    width: 210px !important;
    display: block;
    white-space: nowrap;
    max-width: unset;
    padding: 5px;
	border: none;
	border-radius: 7px;
	color: white;
	opacity: 1;
	cursor: pointer;
}

</style>
