<template>
        <div class="block-wrap">


        <!-- <a :href="`/admin/clients/edit/${getClientSlug}`" class="token-button" v-if="!hasTokens">Authenticate</a> -->
        <button v-if="blockDisable" class="btn-primary btn-primary--outline block-btn" @click.prevent="onAuth()">Authenticate</button>

	<div class="block social blue" v-bind:class="blockDisable">
		<router-link :to="{path: `/client/${getClientSlug}/analytics`}">
			<h3>Analytics</h3>
			<div class="row">
				<p>Sessions</p>
				<div class="stats">
					<p class="number">{{ sessionsVal }}</p>
					<div class="conversion-rate" v-bind:class="conversionClass">
						<div class="grid-container">
							<div class="col">
								<p>{{ conversionAmount }}</p>
							</div>
							<div class="col">
								<span class="arrow">
									<i class="fa-solid fa-arrow-up-long"></i>
								</span>
							</div>
						</div>
					</div>
					<!-- end conversion-rate -->
				</div>
				<!-- end stats -->
				<p class="dark">than last month</p>
			</div>
			<!-- end row -->
			<div class="row graph-row">
				<div class="graph">
					<apexchart ref="demoChart" type="area" height="100" :options="chartOptions" :series="series" v-if="series"></apexchart>
				</div>
				<!-- end graph -->
			</div>
			<!-- end row -->
			<span class="arrow-link">Analytics information</span>
		</router-link>
	</div>
</div>
</template>

<script>
import {mapGetters} from "vuex"

let dailyHits = []
let dailyLabels = []
const metricMom = 'momHits';
const metricDays = 'dailyHits';

export default {
	data() {
		return {
            tokens: {},
			sessionsVal: '',
			conversionAmount: '',
			conversionClass: 'up',
            blockDisable : 'disabled',
            hasTokens: false,
			// Chart settings, note: blank series initially so data can be loaded in.
            chartOptions: {
                chart: {
                    id: 'chart',
                    type: 'area',
                    animations: {
                        enabled: false,
                    },
                    sparkline: {
                        enabled: true,
                    },
                    selection: {
                        enabled: false,
                    },
                },
                colors: ['#5FB9E9'],
                fill: {
                    gradient: {
                        enabled: true,
                        opacityFrom: 0.35,
                        opacityTo: 0
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
					crosshairs: {
                        show: false,
                    },
                },
                stroke: {
                    width: 2,
                    lineCap: 'round',
                    curve: 'smooth'
                },
                tooltip: {
                    enabled: false,
                },
                noData: {
                  text: 'Loading...'
                }
            },
		}
	},
	computed: {
        googleData: {
            get() {
				return this.$store.getters["client/getClientGoogleData"] || ""
            },
		},
		...mapGetters("client", ["getClientSlug", "getSocial", "getComponents"]),
    },
    watch:{
        'getSocial.socialAccountInformation.google': {
        deep: true,
            handler(newValue) {

                this.tokens.googleName = newValue.name;
                this.tokens.googleView = newValue.GA4PropertyName;

                this.init();
            }
        },
    },
    mounted(){
        console.log('block mounted');
        
        this.tokens.googleName = this.getSocial.socialAccountInformation.google.name;
        this.tokens.googleView = this.getSocial.socialAccountInformation.google.GA4PropertyName;
        
        this.init();
    },
    methods:{
        onAuth(){
            const val = this.tokens.googleName ? '' : '[Google Account] must be valid\n';
			const val2 = this.tokens.googleView ? '' : '[Google GA4 property] must be valid\n'
			const str = `Location: Google account information`;
			alert(`${val}${val2}${str}`);
            this.$store.commit('client/setSocial', {prop: 'modalIsOpen', val: true})
        },
        async init(){

            dailyHits = [];
            
            try{

                // Show the month-on-month session data

                if(this.tokens.googleName && this.tokens.googleView){

                    if(this.googleData.metrics){

                        if(this.googleData.metrics[metricMom]){

                            this.sessionsVal = this.googleData.metrics[metricMom].current;

                            this.conversionAmount = (this.sessionsVal - this.googleData.metrics[metricMom].previous);

                            this.conversionClass = this.conversionRate(this.conversionAmount);

                            // Show the graph, loop through the last 30 days of hits and on the last loop update the graph series
                            for (var j = 30; j > 0; j--) {

                                if(this.googleData.metrics[metricDays]){


                                    var savedDate = new Date();

                                    savedDate.setDate(savedDate.getDate() - j);

                                    let thedate = new Date(savedDate);

                                    savedDate = savedDate.toISOString().slice(0, 10);

                                    let currentDaysHits = Number(this.googleData.metrics[metricDays][savedDate]);


                                    // Check that the current hits amount is a number, incase it's not in the list for any reason.
                                    dailyHits.push(currentDaysHits)
                                    let mon = thedate.toLocaleString('en-UK', {month: 'short'});
                                    let dd = thedate.getDate()
                                    dailyLabels.push(mon + ' ' + dd)
                                    if (j === 1) {
                                        this.series = [{
                                        name: 'Hits',
                                        data: dailyHits,
                                        }]
                                    }

                                }

                            }

                        }

                        this.hasTokens = true;
                        this.blockDisable = '';

                    }

                }else{
                    this.hasTokens = false;
                    this.blockDisable = 'disabled';

                }

            }catch(error){
                console.log('anayltics block init error', error);
            }

        }
    },
}

</script>
