<template>

<div class="block-wrap">

    <!-- <a :href="`/admin/clients/edit/${getClientSlug}`" class="token-button" v-if="!hasTokens">Authenticate</a> -->
	<button v-if="blockDisable" class="btn-primary btn-primary--outline block-btn" @click.prevent="onAuth()">Authenticate</button>
	<div class="block ppc blue" v-bind:class="blockDisable">
		<router-link :to="{path: `/client/${getClientSlug}/ppc`}">
			<h3>PPC</h3>
			<div class="row">
				<p>Avg. Cost per click</p>
				<div class="stats">
					<p class="number">£{{ cpcVal ? numberWithCommas(cpcVal) : '--' }}</p>
					<div class="conversion-rate" v-bind:class="cpcClass">
						<div class="grid-container">
							<div class="col">
								<p>{{ formatPercentage(cpcComparison) }}</p>
							</div>
							<div class="col">
								<span class="arrow">
									<i class="fa-solid fa-arrow-up-long"></i>
								</span>
							</div>
						</div>
					</div>
					<!-- end conversion-rate -->
				</div>
				<!-- end stats -->
				<p class="dark">than last month</p>
			</div>
			<!-- end row -->
			<div class="row">
				<p>Conversions</p>
				<div class="stats">
					<p class="number">{{ conversionVal }}</p>
					<div class="conversion-rate" v-bind:class="conversionClass">
						<div class="grid-container">
							<div class="col">
								<p>{{ formatPercentage(conversionComparison) }}</p>
							</div>
							<div class="col">
								<span class="arrow">
									<i class="fa-solid fa-arrow-up-long"></i>
								</span>
							</div>
						</div>
					</div>
					<!-- end conversion-rate -->
				</div>
				<!-- end stats -->
				<p class="dark">than last month</p>
			</div>
			<!-- end row -->
			<span class="arrow-link">PPC information</span>
		</router-link>
	</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex"

const metricCPC = 'googleAdsAvgCPC'
const metricConv = 'googleAdsConversion'

export default {
	computed: {
        googleData: {
            get() {
				return this.$store.getters["client/getClientGoogleData"] || ""
            },
		},
		...mapGetters("client", ["getClientSlug", "getSocial", "getComponents"]),
    },
    watch:{
        'getSocial.socialAccountInformation.google': {
        deep: true,
            handler(newValue) {

                let giveAccess = false;
				
				this.hasAdAccount = this.getSocial.tokens.google.adsAccountID ? true : false;
				this.hasValidToken = newValue.name ? true : false;
				
				if(this.hasAdAccount && this.hasValidToken){

					giveAccess = true;
				}

                this.init(giveAccess);
            }
        },
    },
    async mounted(){

        try{

			let giveAccess = false;
				
			this.hasAdAccount = this.getSocial.tokens.google.adsAccountID ? true : false;
			this.hasValidToken = this.getSocial.socialAccountInformation.google.name ? true : false;
			
			if(this.hasAdAccount && this.hasValidToken){

				giveAccess = true;
			}

            await this.init(giveAccess);

        }catch(error){

            console.log('mounted error', error);

        }
        
    },
    methods:{
		onAuth(){
			const val = this.hasAdAccount ? '' : '[Google Ads Account] must be valid\n';
			const val2 = this.hasValidToken ? '' : '[Google GA4 property] must be valid\n'
			const str = `Location: Google account information`;
			alert(`${val}${val2}${str}`);
            this.$store.commit('client/setSocial', {prop: 'modalIsOpen', val: true})
        },
        async init(giveAccess){

            try{

                if(giveAccess){

					// Cost per click
		  			if(this.googleData.metrics){

						if(this.googleData.metrics[metricCPC] && this.googleData.metrics[metricConv]){

							this.cpcVal = this.googleData.metrics[metricCPC].current
							this.cpcComparison = this.googleData.metrics[metricCPC].previous
							// Set the class based on the rate
							this.cpcClass = this.conversionRate(this.cpcComparison)
							// Conversion
							this.conversionVal = this.googleData.metrics[metricConv].current
							this.conversionComparison = this.googleData.metrics[metricConv].previous
							// Set the class based on the rate
							this.conversionClass = this.conversionRate(this.conversionComparison)

						}else{

							console.error('googleData metrics == false');

						}

					}

                    this.hasTokens = true;
					this.blockDisable = '';
                }else{
					this.hasTokens = false;
					this.blockDisable = 'disabled';
				}
               
            }catch(error){
                console.log('anayltics block init error', error);
            }

        }
    },
	data() {
		return {
            cpcVal: '--',
            cpcComparison: '--',
            cpcClass: 'up',
            conversionVal: '--',
            conversionComparison: '--',
            conversionClass: 'up',
			blockDisable : 'disabled',
			hasAdAccount: false,
			hasValidToken: false
		}
	},
}
</script>
