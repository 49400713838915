<template>
    <div class="block-wrap">

        <!-- <button @click.prevent="this.$store.commit('client/setSocial', {prop: 'modalIsOpen', val: true})" class="token-button" v-if="!hasTokens">Authenticate</button> -->
        <button v-if="blockDisable" class="btn-primary btn-primary--outline block-btn" @click.prevent="onAuth()">Authenticate</button>
        <div class="block social purple" v-bind:class="blockDisable">
            <router-link :to="{path: `/client/${getClientSlug}/paid-social`}">
                <h3>Paid Social</h3>
                <div class="row">
                    <p>Facebook paid conversions</p>
                    <div class="stats">
                        <p class="number">{{ paidSessionsVal }}</p>
                        <div class="conversion-rate" v-bind:class="paidConversionClass">
                            <div class="grid-container">
                                <div class="col">
                                    <p>{{ paidConversionAmount }}</p>
                                </div>
                                <div class="col">
                                    <span class="arrow">
                                        <i class="fa-solid fa-arrow-up-long"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <!-- end conversion-rate -->
                    </div>
                    <!-- end stats -->
                </div>
                <!-- end row -->
                <div class="row graph-row">
                    <div class="graph">
                        <apexchart ref="paidSocialChart" type="area" height="100" :options="chartOptions" :series="series" v-if="series"></apexchart>
                    </div>
                    <!-- end graph -->
                </div>
                <!-- end row -->
                <span class="arrow-link">Paid Social information</span>
            </router-link>
        </div>
    </div>
</template>

<script>
import {mapGetters} from "vuex"

let dailyHits = []
let dailyLabels = []
const metricMom = 'sessionsMom';
const metricDays = 'sessionsByDay';

export default {
	data() {
		return {
            name: false,
            view: false,
            paidSessionsVal: '--',
            paidConversionAmount: '--',
            paidConversionClass: 'up',
            blockDisable : 'disabled',
            hasTokens: false,
			// Chart settings, note: blank series initially so data can be loaded in.
            chartOptions: {
                chart: {
                    id: 'paidSocialChart',
                    type: 'area',
                    animations: {
                        enabled: false,
                    },
                    sparkline: {
                        enabled: true,
                    },
                    selection: {
                        enabled: false,
                    },
                },
                colors: ['#BC8FF8'],
                fill: {
                    gradient: {
                        enabled: true,
                        opacityFrom: 0.35,
                        opacityTo: 0
                    }
                },
                dataLabels: {
                    enabled: false
                },
                xaxis: {
					crosshairs: {
                        show: false,
                    },
                },
                stroke: {
                    width: 2,
                    lineCap: 'round',
                    curve: 'smooth'
                },
                tooltip: {
                    enabled: false,
                },
                noData: {
                  text: 'Loading...'
                }
            },
		}
	},
	computed: {
        googleData: {
            get() {
				return this.$store.getters["client/getClientGoogleData"] || ""
            },
		},
		...mapGetters("client", ["getClientSlug", "getSocial", "getComponents"]),
	},
    mounted(){
        this.name = this.getSocial.socialAccountInformation.google.name;
        this.view = this.getSocial.socialAccountInformation.google.GA4PropertyName;
        if(this.name && this.view){
            this.hasTokens = true;
            this.blockDisable = '';
            this.init();
        }else{
            this.hasTokens = false;
            this.blockDisable = 'disabled';
        }
    },
    watch:{
        'getSocial.socialAccountInformation.google': {
        deep: true,
            handler(newValue) {
                this.name = newValue.name;
                this.view = newValue.GA4PropertyName;
                if(this.name && this.view){
                    this.hasTokens = true;
                    this.blockDisable = '';
                    this.init();
                }else{
                    this.hasTokens = false;
                    this.blockDisable = 'disabled';
                }
            }
        }
    },
    methods: {
        onAuth(){
            const val = this.name ? '' : '[Google Account] must be valid\n';
			const val2 = this.view ? '' : '[Google GA4 property] must be valid\n'
			const str = `Location: Google account information`;
			alert(`${val}${val2}${str}`);
            this.$store.commit('client/setSocial', {prop: 'modalIsOpen', val: true})
        },
        init(){

            dailyHits = [];

                // Show the month-on-month session data

                if(this.googleData.metrics){

                    if(this.googleData.metrics[metricMom]){

                    this.paidSessionsVal = this.googleData.metrics[metricMom].currentPaid
                    this.paidConversionAmount = (this.paidSessionsVal - this.googleData.metrics[metricMom].previousPaid);
                    this.paidConversionClass = this.conversionRate(this.paidConversionAmount)
                    // Show the graph, loop through the last 30 days of hits and on the last loop update the graph series
                    for (var j = 30; j > 0; j--) {
                        var savedDate = new Date();
                        savedDate.setDate(savedDate.getDate() - j);
                        let thedate = new Date(savedDate);
                        savedDate = savedDate.toISOString().slice(0, 10);
                        let currentDaysHits = this.googleData.metrics[metricDays].paid[savedDate]

                        // Check that the current hits amount is a number, incase it's not in the list for any reason.
                        if (Number.isInteger(currentDaysHits)) {
                            dailyHits.push(currentDaysHits)
                            let mon = thedate.toLocaleString('en-UK', {month: 'short'});
                            let dd = thedate.getDate()
                            dailyLabels.push(mon + ' ' + dd)
                        }
                        if (j === 1) {
                            this.series = [{
                            name: 'Hits',
                            data: dailyHits,
                            }]
                        }
                    }

                    }

                }
        }
    },      
}
</script>